/* eslint-disable @next/next/no-img-element */
import * as React from "react";
import { useRouter } from "next/router";
import { Header } from "@nju/feature-portal-header";
import { Footer } from "@nju/feature-portal-footer";
import { BlockContent, Heading, LayoutWrapper } from "@nju/ui";

export default function MaintenanceModePage() {
  const router = useRouter();
  const [showPageContent, setShowPageContent] = React.useState(false);

  React.useEffect(() => {
    const pathsToRedirect = new Set([
      "/CiK7rI/c728ZgmXxEqsTG2zAVtj",
      "/Uzu8oe/rV6KzeEoW1M9LXyYp5Sf",
      "/nju.pl",
      "/swiatlowod,",
      "/k2ZO6g/if0IkTQyXY872tB-ANO6",
      "/c8i1JU/0Pinuj-bGMJXep3QIfl4",
      "/dpsfLz/R035E3F5o8p6Hb1U7lVD",
      "/39rkWS/VY21XU1mr9CLckpT-ZlF",
      "/nxNy6P/5yqS7nbrjEgkZT0A41GQ",
      "/PEWZfq/L5nsBapbi0eo9wDyhQMY",
      "/6HCs3c/rU2vPHYG01b839FmwoAS",
      "/56rD73/qjps71c1KtCGDo37baWr",
      "/8Kqfus/98F0l1yu7LIO4PU25Q71",
      "/XFD7im/0WYzI6kHFbjEhe2ArG1o",
      "/iXG-Q7/2vY5bxul219XjC8yftN9",
      "/iT4B3-/cCXySv-oIYAdV00emsPh",
      "/kaIF9p/79Bzt2KpOr46TYy30XAi",
      "/KWcwh1/dr33OpZ-B2D57Y0164q1",
      "/5NaHJd/R4B8KZcQfE7wIrigY6jo",
      "/4A3972/H2cGgij2KzFhe6v4ktVP",
      "/6Xf2-4/h8s1VWYZFx1vJR3Dce89",
      "/8epwiU/2Vz9-AUNE04w6Tk39xt5",
      "/G0w7yj/R-Q1N52EjUcug6MZDAsY",
      "/Z2Dp6L/f7vpB1T6u2A58EJ0L8i9",
      "/2VgUI0/879heSP1VsKd25vRxXW3",
      "/DCLiAz/h9cODjf7bq1yCM1LVAK0",
      "/RI58Lt/ZiTGnCd2RDg3MBwob7m0",
      "/6Fg25x/G3NQ9E0iI1lM4n62TScf",
      "/hPc5s1/UOTX6jni2vRh8J7MGeIl",
      "/kzO9H6/RkdYOcSGj13tANwL3Ei4",
      "/08o9pZ/PV9u12XOIDqv51LJcB6N",
      "/raf98v/04xC2zywTo-j0nNP9WuM",
      "/vy46pM/J494TMYH5yhnF28QlL1u",
      "/FdzY46/Fo-nB6GEhxkU9z50sv18",
      "/gZ-3ad/83QFkT32Mn4-Z1KGO9oL",
      "/i4GmAE/3Dz6jf7O211ZPhMrpab4",
      "/kKo41B/D5cpY84o8XTdvPJ79f0e",
      "/CSukz4/6074V3lR9Ui150AYWB9F",
      "/PmQzVY/Ck82GaZD5d5yocb6u9h3",
      "/obsluga/forum/id,100000001,title,zmiana-karty-sim,watek",
      "/5yqwck/YzhUc12-pQaljextqv3u",
      "/KDY2O7/R6H1T2X8Jom3tvwNL1-n",
      "/IY7brN/TInEW1kX7t05pU5ANO4S",
      "/bZ2twi/5W2C4LR9pYBMPH-m1Ug6",
      "/JTm7D3/wk7-35NFZUxCeQKDOyoI",
      "/wfrxiu/5c7MrUP8NL5kR9v3lmt2",
      "/8PDOAl/J22l6qU1hM478sbNx3Tc",
      "/6Q65-t/1SmHxu9Z0jCc9sbqJQNB",
      "/F63B-N/QifRN4132g28EdUjlekM",
      "/FCt4bq/YcCxj7s-XknM4GhINeF8",
      "/6Wi0hU/9ZpnWs4H2f-6eA5Vydh6",
      "/obsluga/forum/id,104300005,title,taryfa-jeden-na-wszystko-za-jaka-infolinie-p-aci-sie-,watek?cite=105000010&forumElementId=104300005",
      "/obsluga/forum/id,16400004,title,kod-migracyjny-,watek?cite=34400002&pageNo=1&forumElementId=16400004",
      "/obsluga/faktury-i-platnosci?start=4&howMany=3&pi=6600029",
      "/obsluga/forum/id,117000004,title,problem-z-adresami-i-kontaktem-sklep-njumobile-pl-i-przechodze-do-njumobile-pl-,watek?cite=117000004&pageNo=1&forumElementId=117000004",
      "/obsluga/forum/id,9200009,title,sms-internet-na-102-nie-dzia-a-,watek",
      "/obsluga/forum/id,71500003,title,faktura-papierowa,watek",
      "/obsluga/forum/id,4700004,title,jak-wy-aczyc-sprawdzanie-kodu-pin-,watek?cite=106900007&pageNo=5&forumElementId=4700004",
    ]);

    if (pathsToRedirect.has(router.asPath)) {
      router.push("/");
      setShowPageContent(false);

      return;
    }

    setShowPageContent(true);
  }, [router]);

  return (
    <LayoutWrapper>
      <Header />
      {showPageContent && (
        <div className="bg-nav-gradient min-h-screen flex flex-col">
          <BlockContent spacing="m"></BlockContent>
          <div className="flex flex-1 items-end">
            <BlockContent>
              <div className="flex items-center only-mobile:flex-col from-tablet:justify-between">
                <div className="flex-1 flex flex-col">
                  <Heading
                    className="only-mobile:text-size-20 only-mobile:mb-3"
                    tone="secondary"
                    variant="h1"
                  >
                    UPS
                  </Heading>
                  <p className="text-white text-size-32 only-mobile:text-size-20 mt-1">
                    strona nie została odnaleziona
                  </p>
                </div>
                <div className="flex-1">
                  <img
                    alt="pipik_maintenance"
                    className="max-h-full only-mobile:mt-6 only-mobile:max-h-[250px]"
                    src="/images/pipik_no_internet.svg"
                  />
                </div>
              </div>
            </BlockContent>
          </div>
          <Footer />
        </div>
      )}
    </LayoutWrapper>
  );
}
